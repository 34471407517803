export const icons = [
  "fab fa-skype",
  "fas fa-phone-square",
  "fab fa-whatsapp-square",
  "fas fa-at"
];

export const id = ["skype", "phone", "whatsapp", "mail"];

export const info = [
  "live:mahmutkaya.nl",
  "+31686433636",
  "+31686433636",
  "mahmutkaya.nl@gmail.com"
];
